@use 'abstracts/variables';
@use 'abstracts/colors';

%table {
  background-color: #fff;
  border: 1px solid rgba(29, 69, 104, 12%);
  border-radius: 2px;
  border-spacing: 4px 0;
  box-shadow: 0 0 8px rgba(29, 69, 104, 8%);
  font-size: map-get(variables.$font-size, 'tiny');
  padding: 4px 0;
  width: 100%;
}

.table {
  @extend %table;

  th {
    background-color: rgba(map-get(colors.$color, 'background-05'), 0.04);
    color: map-get(colors.$color, 'background-05');
    letter-spacing: 0.5px;
    padding: 12px 8px;
    text-transform: uppercase;
  }

  td {
    color: map-get(colors.$color, 'text-secondary');
    letter-spacing: 0.2px;
    padding: 12px 4px;
  }

  tr:not(:first-child) td {
    border-top: 1px solid rgba(29, 69, 104, 12%);
  }
}

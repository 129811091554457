// Configuration and helpers
@import 'abstracts/variables';

// Bootstrap
@import 'abstracts/bootstrap';
@import 'abstracts/bootstrap-utils';

// Material Theme
@import 'abstracts/angular-material';
@import 'handsontable/dist/handsontable.full.css';

// Global styles
@import 'base';
@import 'components';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
